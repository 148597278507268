/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

.hidden-scrollbar {
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  /* Hide scrollbar for IE and Edge */
  -ms-overflow-style: none; /* Internet Explorer and Edge */

  /* Hide scrollbar for Firefox */
  scrollbar-width: none; /* Firefox */
}

/* Track */
.dark {
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: #888;
    border-radius: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #000;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.097);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ebeaea;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

@import "ngx-toastr/toastr";

@layer components {
  .modal-with-static-header-footer {
    & .static-header {
      height: 4rem;
      width: 100%;
      z-index: 1;
    }
    & .static-body {
      max-height: calc(90vh - 7rem);
      overflow: auto;
    }
    & .static-footer {
      height: 3rem;
      z-index: 1;
    }
  }
}

/* Date picker style change on select */
.datepicker-cell.selected,
.datepicker-cell[aria-selected="true"] {
  background-color: #ff5a1f !important; /* Change to Green */
  color: white !important;
  border-radius: 0.375rem; /* Optional: Adds rounded corners */
}
